.whitefade1 {
  background: linear-gradient(
    269.81deg,
    rgba(255, 255, 255, 0.31) 22.83%,
    rgba(102, 173, 243, 0.01) 84.54%
  );
  width: 802.24px;
  height: 135.7px;
  position: absolute;
  transform: rotate(-161.83deg) translate(4px, -10px);
  top: 1;
}

.whitefade2 {
  background: linear-gradient(
    269.81deg,
    rgba(255, 255, 255, 0.31) 22.83%,
    rgba(102, 173, 243, 0.01) 84.54%
  );
  width: 550px;
  height: 135.7px;
  position: absolute;
  transform: rotate(-134.58deg) translate(-35px, -190px);
}

.whitefade3 {
  background: linear-gradient(
    269.81deg,
    rgba(255, 255, 255, 0.31) 22.83%,
    rgba(102, 173, 243, 0.01) 84.54%
  );
  width: 522.24px;
  height: 135.7px;
  position: absolute;
  transform: rotate(150.69deg) translate(400px, -520px);
}
